import { Tag, TagLabel } from '@chakra-ui/react';

// eslint-disable-next-line consistent-return
export function statusFormat(value) {
  if (value?.toLowerCase() === 'success' || value?.toLowerCase() === 'completed') {
    return (
      <Tag colorScheme="green">
        <TagLabel style={{ color: 'darkgreen' }}>SUCCESS</TagLabel>
      </Tag>
    );
  }

  if (value?.toLowerCase() === 'pending') {
    return (
      <Tag colorScheme="yellow">
        <TagLabel style={{ color: 'yellowgreen' }}>PENDING</TagLabel>
      </Tag>
    );
  }

  if (value?.toLowerCase() === 'failed') {
    return (
      <Tag colorScheme="red">
        <TagLabel style={{ color: 'darkred' }}>FAILED</TagLabel>
      </Tag>
    );
  }
}
