import React, { useEffect, useState, useMemo } from 'react';
import { TableHeader, Pagination, Search } from '@app/components/Datatable';
import { Table, Th, Td, PaginationInfo, Label, InputDate, SelectR, ExportToExcel } from '@app/components';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-modern-calendar-datepicker';

import useFullPageLoader from '@app/hooks/useFullPageLoader';
import { Input } from '@app/components/Forms/Input';
import { CalendarIcon, SearchIcon, XIcon } from '@heroicons/react/outline';
import * as localStorageUtil from '@app/utils/localstorage.util';
import { GetMerchantTransaction, postLogin, useGetMerchantHistoryTransaction } from '@app/services/merchant-service';
import { InfoCard } from '@app/pages/Home/InfoCard';
import { formatRupiah } from '@app/utils/formatRupiah';
import moment from 'moment';
import { formatDate, formatDateDayEng, formatDateDayId, formatDateMoment } from '@app/utils/date.util';
import { capitalize } from '@app/utils/capitalize';
import { DateRangePicker } from 'react-date-range';
import useDebounce from '@app/hooks/useDebounce';
import { addDays } from 'date-fns';
import format from 'date-fns/format';
import clsx from 'clsx';
import { Formik } from 'formik';
import { statusFormat } from '@app/utils/status';

const DataTable = () => {
  const defaultFrom = {
    year: 2019,
    month: 4,
    day: 16,
  };
  const defaultTo = {
    year: 2019,
    month: 4,
    day: 19,
  };
  const defaultValue = {
    from: defaultFrom,
    to: defaultTo,
  };
  const [dataTrx, setDataTrx] = useState<GetMerchantTransaction>({
    error: false,
    message: '',
    result: { filteredCount: 0, rows: [], totalCount: 0 },
    status: '',
  });
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  // const [isLoading, setIsLoading] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTransactionFiltered, setTotalTransactionFiltered] = useState(0);
  const [transactionCompleted, setTransactionCompleted] = useState(0);
  const [transactionPending, setTransactionPending] = useState(0);
  const [transactionPendingInDay, setTransactionPendingInDay] = useState(0);
  const [transactionCompletedInDay, setTransactionCompletedInDay] = useState(0);
  const [totalTransactionCompleted, setTotalTransactionCompleted] = useState(0);
  const [totalTransactionCompletedInDay, setTotalTransactionCompletedInDay] = useState(0);
  const [totalTransactionPending, setTotalTransactionPending] = useState(0);
  const [totalTransactionPendingInDay, setTotalTransactionPendingInDay] = useState(0);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [selectedDayRange, setSelectedDayRange] = useState(
    defaultValue,
  );
  const [selectedDay, setSelectedDay] = useState(null);
  const [dates, setDates] = useState({
    from: null,
    to: null,
  });

  const [successDate, setSuccessDate] = useState({
    from: null,
    to: null,
  });

  const [trxStatusG, setTrxStatusG] = useState('');

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  const [companyName, setCompanyName] = useState('');
  const [mid, setMid] = useState('');

  // eslint-disable-next-line eqeqeq

  const debouncedKeyword = useDebounce(search, 500);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: 'No', field: 'id', sortable: false },
    { name: 'Merchant Order ID', field: 'merchantOrderID', sortable: true },
    { name: 'Reference ID', field: 'referenceId', sortable: true },
    { name: 'Product Code', field: 'productCode', sortable: true },
    { name: 'Amount', field: 'sellingPrice', sortable: false },
    { name: 'Trx Status', field: 'transactionStatus', sortable: false },
    { name: 'Created Date', field: 'createdDate', sortable: true },
    { name: 'Trx Success Date', field: 'updateDate', sortable: true },

  ];
  // async function initTransaction() {
  const { data, isFetching, isLoading, payload, setPayload, refetch } = useGetMerchantHistoryTransaction();
  // }

  const range = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month'),
    ],
    'Last Year': [
      moment()
        .subtract(1, 'year')
        .startOf('year'),
      moment()
        .subtract(1, 'year')
        .endOf('year'),
    ],
  };

  const handleEvent = (datRange) => {
    setState([
      {
        startDate: datRange.selection.startDate,
        endDate: datRange.selection.endDate,
        key: datRange.selection.key,

      },
    ]);
    console.log('start: ', datRange);
  };

  const handleEventRange = (datRange) => {
    console.log('start: ', datRange);
  };

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };

  // useEffect(() => {
  //   // setPayload({ mid: parsedObject.mid, merchantToken: parsedObject.merchantToken });
  //
  //   const getData = () => {
  //     // const { data, isFetching, isLoading, payload, setPayload, refetch } = useGetMerchantHistoryTransaction();
  //     // console.log('data ada ?', data);
  //     // // @ts-ignore
  //     // showLoader();
  //     //
  //     // fetch('https://jsonplaceholder.typicode.com/comments')
  //     //   .then((response) => response.json())
  //     //   .then((json) => {
  //     //     // @ts-ignore
  //     //     hideLoader();
  //     //     setComments(json);
  //     //     console.log(json);
  //     //   });
  //
  //     // if (data != null) {
  //     //   setDatas(datas);
  //     // }
  //     // console.log('get data mana2 ?', data?.result);
  //   };
  //
  //   getData();
  // }, []);

  useEffect(() => {
    // setPayload({ mid: '' });
    const retrievedObject = localStorage.getItem('auth');
    const parsedObject = JSON.parse(retrievedObject);
    // mid(parsedObject.mid);
    setMid(parsedObject.mid);
    setCompanyName(parsedObject.name);
  });

  // eslint-disable-next-line consistent-return
  const commentsData = useMemo(() => {
    console.log('get data mana ?');
    if (data?.result) {
      let computedComments = data.result.rows;
      // computedComments.map((item) => item.sellingPrice).reduce((prev, next) => prev + next);

      const date = new Date();
      const from = new Date(date.getFullYear(), date.getMonth(), 1);
      const toEndOfTheMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const dFrom = formatDateMoment(from, 'YYYY-MM-DD 00:00:01');
      const dTo = formatDateMoment(toEndOfTheMonth, 'YYYY-MM-DD 23:59:59');
      const dToStartOfTheDay = formatDateMoment(date, 'YYYY-MM-DD 00:00:01');
      const dToEndOfTheDay = formatDateMoment(date, 'YYYY-MM-DD 23:59:59');

      // const totalCompleted = computedComments
      //   .filter(
      //     (item) => {
      //       const date = new Date(item.createdDate);
      //       const trxStatus = item.transctionStatus === 'Completed';
      //       return (trxStatus && (date >= from && date <= to));
      //       // item.transctionStatus === 'Completed';
      //     },
      //   )
      //   .reduce((accumulator, item: any) => {
      //     return accumulator + item.sellingPrice;
      //   }, 0);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const totalCompletedInMonth = totalAmountTransaction(data, dFrom, dTo, ['Completed', 'Success'], 'month');
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const totCompletedInMonth = totalTransaction(data, dFrom, dTo, ['Completed', 'Success'], 'month');
      setTotalTransactionCompleted(totalCompletedInMonth);
      setTransactionCompleted(totCompletedInMonth);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const totalPendingInMonth = totalAmountTransaction(data, dFrom, dTo, ['Pending'], 'month');
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const totPendingInMonth = totalTransaction(data, dFrom, dTo, ['Pending'], 'month');
      setTotalTransactionPending(totalPendingInMonth);
      setTransactionPending(totPendingInMonth);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const totalCompletedInDay = totalAmountTransaction(data, dToStartOfTheDay, dToEndOfTheDay, ['Completed', 'Success'], 'day');
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const totCompletedInDay = totalTransaction(data, dToStartOfTheDay, dToEndOfTheDay, ['Completed', 'Success'], 'day');
      setTotalTransactionCompletedInDay(totalCompletedInDay);
      setTransactionCompletedInDay(totCompletedInDay);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const totalPendingInDay = totalAmountTransaction(data, dToStartOfTheDay, dToEndOfTheDay, ['Pending'], 'day');
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const totPendingInDay = totalTransaction(data, dToStartOfTheDay, dToEndOfTheDay, ['Pending'], 'day');
      // console.log('pending day :', totalPendingInDay);
      setTotalTransactionPendingInDay(totalPendingInDay);
      setTransactionPendingInDay(totPendingInDay);

      if (debouncedKeyword) {
        computedComments = computedComments.filter(
          (comment) => comment?.hp.toLowerCase().includes(debouncedKeyword.toLowerCase())
                || comment?.merchantOrderID.toLowerCase().includes(debouncedKeyword.toLowerCase()) || comment?.referenceId.toLowerCase().includes(search.toLowerCase())
                || comment?.sellingPrice.toString().includes(debouncedKeyword) || comment?.transctionStatus.toLowerCase().includes(debouncedKeyword.toLowerCase()),
        );
      }

      if (trxStatusG && ((dates?.from && dates?.to) || (successDate?.from && successDate?.to))) {
        const dFromFilter = formatDateMoment(dates.from, 'YYYY-MM-DD 00:00:01');
        const dToFilter = formatDateMoment(dates.to, 'YYYY-MM-DD 23:59:59');

        const dFromSuccessFilter = formatDateMoment(successDate.from, 'YYYY-MM-DD 00:00:01');
        const dToSuccessFilter = formatDateMoment(successDate.to, 'YYYY-MM-DD 23:59:59');
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        computedComments = (trxStatusG === 'All') ? getAllTransaction(data, dFromFilter, dToFilter, dFromSuccessFilter, dToSuccessFilter) : getAllTransactionByRangeAndStatus(data, dFromFilter, dToFilter, trxStatusG, dFromSuccessFilter, dToSuccessFilter);
        const total = computedComments.reduce((accumulator, item: any) => {
          return accumulator + item.sellingPrice;
        }, 0);
        setTotalTransactionFiltered(total);
        setDataTrx({
          error: false,
          message: '',
          status: '',
          result: { filteredCount: 0, rows: computedComments, totalCount: 0 },

        }); // ExportToExcel({ apiData: computedComments, fileName: '' });
      } else {
        const total = computedComments.reduce((accumulator, item: any) => {
          return accumulator + item.sellingPrice;
        }, 0);
        setTotalTransactionFiltered(total);
      }

      setTotalItems(computedComments.length);

      // Sorting comments
      if (sorting.field) {
        const reversed = sorting.order === 'asc' ? 1 : -1;
        computedComments = computedComments.sort(
          (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]),
        );
      }

      // Current Page slice
      return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
      );
    }
  }, [data?.result.rows, currentPage, debouncedKeyword, sorting, trxStatusG, dates, successDate]);

  const onSubmit = (values : any) => {
    try {
      console.log(values);
      setTrxStatusG(values.status);
      setDates(values.trx_date);
      setSuccessDate(values.trx_success_date);
    } catch (error) {
      console.log(error);
    }
  };

  const click = () => {
    console.log('download');
    // ExportToExcel({ apiData: dataTrx.result.rows, fileName: '' });
  };
  return (
    <>
      {/* <ExternalInfo page="datatable" /> */}
      <div className="flex-1 p-4 bg-white rounded-[22px] gap-4 grid grid-cols-2">
        <InfoCard label="Total Transaction Complete Today" icon="/assets/icon/wallet-orange.svg" value={`${formatRupiah(totalTransactionCompletedInDay)}(${transactionCompletedInDay})`} variant="blue" />
        <InfoCard label="Total Transaction Pending Today" icon="/assets/icon/wallet-orange.svg" value={`${formatRupiah(totalTransactionPendingInDay)}(${transactionPendingInDay})`} variant="green" />
        <InfoCard label="Total Transaction Complete This Month" icon="/assets/icon/wallet-orange.svg" value={`${formatRupiah(totalTransactionCompleted)}(${transactionCompleted})`} variant="purple" />
        <InfoCard label="Total Transaction Pending This Month" icon="/assets/icon/wallet-orange.svg" value={`${formatRupiah(totalTransactionPending)}(${transactionPending})`} variant="purple" />
        {/* <InfoCard label="Jumlah Pasien (Populasi yang Aktif Berobat)" icons="/assets/icons/syringe.svg" value="150" variant="orange" /> */}
      </div>

      <div className="p-5">
        <div className="bg-white px-5 py-5">
          <Label font="bold" size="lg" htmlFor="username">Filter</Label>
          <div className="mt-5 mb-5 border border-b border-gray-200" />
          <Formik
            initialValues={{ status: '',
              trx_date: {
                from: null,
                to: null,
              },
              trx_success_date: {
                from: null,
                to: null,
              } }}
            enableReinitialize
            onSubmit={(values) => {
              onSubmit({
                trx_date: {
                  from: values.trx_date.from,
                  to: values.trx_date.to,
                },
                trx_success_date: {
                  from: values.trx_success_date.from,
                  to: values.trx_success_date.to,
                },
                status: values.status,
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex justify-between items-center">
                  <div className="flex space-x-4">
                    <InputDate
                      value={values.trx_date}
                      minimumDate={{
                        year: new Date().getFullYear() - 10,
                        month: 1,
                        day: 1,
                      }}
                      maximumDate={{
                        year: new Date().getFullYear() + 100,
                        month: new Date().getMonth() + 1,
                        day: new Date().getDate(),
                      }}
                      onChange={(value) => setFieldValue('trx_date', value)}
                      placeholder="Transaction Date"
                      id="transaction_date"
                    />
                    {/* <SelectR */}
                    {/*  name="category" */}
                    {/*  id="category" */}
                    {/*  placeholder="Kepesertaan" */}
                    {/*  value="ok" */}
                    {/* > */}
                    {/*  <option value="">Pilih</option> */}
                    {/*  <option value="kapitasi">Kapitasi</option> */}
                    {/*  <option value="ffs">ffs</option> */}
                    {/* </SelectR> */}

                    {/* </div> */}

                    <InputDate
                      value={values.trx_success_date}
                      minimumDate={{
                        year: new Date().getFullYear() - 10,
                        month: 1,
                        day: 1,
                      }}
                      maximumDate={{
                        year: new Date().getFullYear() + 100,
                        month: new Date().getMonth() + 1,
                        day: new Date().getDate(),
                      }}
                      onChange={(value) => setFieldValue('trx_success_date', value)}
                      placeholder="Transaction Success Date"
                      id="transaction_success_date"
                    />

                    <SelectR
                      name="status"
                      id="status"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="status"
                      value={values.status}
                    >
                      <option value="">Choose</option>
                      <option value="All">All</option>
                      <option value="Completed">Completed</option>
                      <option value="Pending">Pending</option>
                      <option value="Failed">Failed</option>
                    </SelectR>
                  </div>
                  <div className="flex space-x-4 items-center">
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                      Search
                    </button>
                    <ExportToExcel apiData={dataTrx?.result?.rows} fileName={`${mid}_TRANSACTION_REPORT-${(dates?.from) ? (`${dates?.from}  TO`) : ''} ${(dates?.to) ? dates?.to : ''}`} />
                    {/* <button onClick={click} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"> */}
                    {/*  Export */}
                    {/* </button> */}
                  </div>
                </div>
              </form>
            )}
          </Formik>

          {/* <InputDate */}
          {/*  id="effective_date" */}
          {/*  value={defaultValue} */}
          {/*    // value={(values)=> { */}
          {/*    //   return values.effective_date */}
          {/*    // }} */}
          {/*  onChange={(value) => { */}
          {/*    console.log(value); */}
          {/*  }} */}
          {/*  placeholder="Tanggal Efektif" */}
          {/* /> */}
        </div>
      </div>

      <div className="p-5">
        <div className="bg-white px-5 border border-gray-200 rounded-lg">
          <div className="flex space-x-2 mt-7 mb-7 justify-between">
            <div className="flex none">
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="lg"
                variant="outline"
                placeholder="Cari"
                rightIcon={
                  <SearchIcon className="w-5 h-5 text-gray-600" />
                  }
                tabIndex="0"
              />

            </div>

            <div className="flex flex-col">
              <div>
                <p className="text-sm font-medium text-gray-600 text-right">All Total Transaction : </p>
              </div>
              <div>
                <p className="text-[20px] font-semibold text-gray-600 text-right">{formatRupiah(totalTransactionFiltered)}</p>
              </div>

            </div>
            {/* <Select */}
            {/*  className="mt-1" */}
            {/*  name="is_active" */}
            {/*  placeholder="Status" */}
            {/*  id="is_active" */}
            {/* > */}
            {/*  <option value="kategori">Kategori</option> */}
            {/*  <option value="jaminan">Jaminan</option> */}
            {/* </Select> */}
          </div>
          <div className="overflow-x-auto">
            <Table loading={isLoading} fetching={isFetching} empty={!(data?.result.rows || []).length}>
              <TableHeader
                headers={headers}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              {/* <thead className="border-b"> */}
              {/*  <tr className="h-12 font-semibold"> */}
              {/*    <Th columnKey="id">No.</Th> */}
              {/*    <Th columnKey="name" sort={sort} onToggleSort={(columnKey) => setSorting({ columnKey })}>Name</Th> */}
              {/*    <Th columnKey="email">Email</Th> */}
              {/*    <Th columnKey="body">Body</Th> */}
              {/*  </tr> */}
              {/* </thead> */}
              <tbody>
                {commentsData?.map((comment, index) => (
                  <tr className="border-b border-gray-200 hover:bg-gray-50" key={index}>
                    <Th columnKey={index.toString()}>
                      {(currentPage - 1) * ITEMS_PER_PAGE + index + 1}
                    </Th>
                    <Td>{comment?.merchantOrderID}</Td>
                    <Td>{(comment?.referenceId)}</Td>
                    <Td>{(comment?.productCode)}</Td>
                    <Td>{formatRupiah(comment?.sellingPrice)}</Td>
                    <Td>{statusFormat(comment?.transctionStatus)}</Td>
                    <Td>{formatDateDayEng(comment?.createdDate)}</Td>
                    <Td>
                      {(comment?.transctionStatus === 'Completed' || comment?.transctionStatus === 'Success') ? formatDateDayEng(comment?.updateDate) : '-'}
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="p-5 flex justify-between">
              <div className="flex space-x-5 items-center">
                {/* <div className="flex space-x-2"> */}
                {/*  <div className="flex items-center text-sm text-gray-800"> */}
                {/*    Show */}
                {/*  </div> */}
                {/*  <div className="w-20"> */}
                {/*    <Select */}
                {/*      name="page_size" */}
                {/*      onChange={(event) => { */}
                {/*        setPage(1); */}
                {/*        setPageSize(event.target.value); */}
                {/*      }} */}
                {/*      value={pageSize} */}
                {/*    > */}
                {/*      <option value="5">5</option> */}
                {/*      <option value="10">10</option> */}
                {/*      <option value="20">20</option> */}
                {/*    </Select> */}
                {/*  </div> */}
                {/* </div> */}
                <PaginationInfo
                  currentPage={currentPage}
                  totalItems={totalItems}
                  pageSize={ITEMS_PER_PAGE}
                />
              </div>

              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>

        </div>
      </div>
      {/* <div className="row w-100"> */}
      {/*  <div className="col mb-3 col-12 text-center"> */}
      {/*    <div className="row"> */}
      {/*      <div className="col-md-6"> */}
      {/*        <Pagination */}
      {/*          total={totalItems} */}
      {/*          itemsPerPage={ITEMS_PER_PAGE} */}
      {/*          currentPage={currentPage} */}
      {/*          onPageChange={(page) => setCurrentPage(page)} */}
      {/*        /> */}
      {/*      </div> */}
      {/*      <div className="col-md-6 d-flex flex-row-reverse"> */}
      {/*        <Search */}
      {/*          onSearch={(value) => { */}
      {/*            setSearch(value); */}
      {/*            setCurrentPage(1); */}
      {/*          }} */}
      {/*        /> */}
      {/*      </div> */}
      {/*    </div> */}
      {/*    <div className="mt-8 bg-white rounded-md" /> */}
      {/*  </div> */}
      {/* </div> */}
      {loader}
    </>
  );

  function getAllTransactionByRangeAndStatus(responData: GetMerchantTransaction, startDate: string, endDate: string, status: string, dFromSuccessFilter: string, dToSuccessFilter: string) {
    return responData.result.rows
      .filter(
        (item) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const dateTrx = new Date(item.createdDate);
          const dateTrxStr = formatDateMoment(dateTrx, 'YYYY-MM-DD 00:00:01');
          const dateTrxSuccess = new Date(item.updateDate);
          const dateTrxStrSuccess = formatDateMoment(dateTrxSuccess, 'YYYY-MM-DD 00:00:01');

          const trxStatus = item.transctionStatus.includes(status);
          // eslint-disable-next-line no-mixed-operators
          return (trxStatus && ((dateTrxStr >= startDate && dateTrxStr <= endDate) || (dateTrxStrSuccess >= dFromSuccessFilter && dateTrxStrSuccess <= dToSuccessFilter)));
          // item.transctionStatus === 'Completed';
        },
      );
    // .reduce((accumulator, item: any) => {
    //   return accumulator + item.sellingPrice;
    // }, 0);
  }

  function getAllTransaction(responData: GetMerchantTransaction, startDate: string, endDate: string, dFromSuccessFilter: string, dToSuccessFilter: string) {
    return responData.result.rows
      .filter(
        (item) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const dateTrx = new Date(item.createdDate);
          const dateTrxStr = formatDateMoment(dateTrx, 'YYYY-MM-DD 00:00:01');
          const dateTrxSuccess = new Date(item.updateDate);
          const dateTrxStrSuccess = formatDateMoment(dateTrxSuccess, 'YYYY-MM-DD 00:00:01');
          return (dateTrxStr >= startDate && dateTrxStr <= endDate) || (dateTrxStrSuccess >= dFromSuccessFilter && dateTrxStrSuccess <= dToSuccessFilter);
          // item.transctionStatus === 'Completed';
        },
      );
    // .reduce((accumulator, item: any) => {
    //   return accumulator + item.sellingPrice;
    // }, 0);
  }

  function totalAmountTransaction(responData: GetMerchantTransaction, startDate: string, endDate: string, status: string[], period: string) {
    return responData.result.rows
      .filter(
        (item) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          // console.log('false ? ', item.transctionStatus.includes(status.ts));
          const dateTrx = new Date(item.createdDate);
          const dateTrxStr = formatDateMoment(dateTrx, 'YYYY-MM-DD 00:00:01');
          const trxStatus = status.includes(item.transctionStatus);
          return (trxStatus && (dateTrxStr >= startDate && dateTrxStr <= endDate));
          // item.transctionStatus === 'Completed';
        },
      )
      .reduce((accumulator, item: any) => {
        return accumulator + item.sellingPrice;
      }, 0);
  }

  function totalTransaction(responData: GetMerchantTransaction, startDate: string, endDate: string, status: string[], period: string) {
    return responData.result.rows
      .filter(
        (item) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const dateTrx = new Date(item.createdDate);
          const dateTrxStr = formatDateMoment(dateTrx, 'YYYY-MM-DD 00:00:01');
          const trxStatus = status.includes(item.transctionStatus);
          return (trxStatus && (dateTrxStr >= startDate && dateTrxStr <= endDate));
          // item.transctionStatus === 'Completed';
        },
      )
      .reduce((accumulator, item: any) => {
        return accumulator + 1;
      }, 0);
  }
};
export default DataTable;
